<template>
  <div>
    <b-card title="Diseño de marco completado" class="text-center">
      <b-row class="mb-4">
        <div style="width: 100%">
          <table id="tap" class="table" v-if="tapeta[0]" style="height: 25px">
            <tr><td class="tapeta" colspan="3"></td></tr>
          </table>
          <table id="registro" class="table" :style="'height :'+ (register_y) +'px'" v-if="register">
            <tr>
              <td class="tapeta" v-if="tapeta[2]"></td>
              <td :colspan="vdivisions" style="border : 1px solid black">
                  <div class="d-flex" style="height : 100% ; flex-direction : column; justify-content: space-evenly">
                    <div class="d-flex innerDiv" :style="'height : 100%; background-image: url(\''+ bg_img + '\')'"> 
                      <span class="input-left text-center">{{register}}</span>             
                    </div>
                  </div>          
              </td>
              <td class="tapeta" v-if="tapeta[3]"></td>
            </tr>              
          </table>
            <table id="esquema" class="table table-bordered" :style="'height :'+ table_y +'px'">
              <tr>
                <td class="tapeta" v-if="tapeta[2]"></td>
                <td v-for="vdivision,index in dessign" :key="index" :style="'width : '+ (vdivision.percent/100) * table_x+'px'">
                  <div class="d-flex" style="height : 100% ; flex-direction : column; justify-content: space-evenly">
                    <div class="d-flex innerDiv" v-for="hdivision,key in vdivision.rows" :key="key" 
                    :style="'height : '+ hdivision.percent +'%; background-image: url(\''+ bg_img + '\')'"> 
                      <span class="input-left text-center">{{hdivision.height}}</span>
                      <img class="bottom-arrow img-fluid" :src="bottom_img" >
                      <span class="input-bottom text-center">{{vdivision.width}}</span>
                    </div>
                  </div>
                </td>
                <td class="tapeta" v-if="tapeta[3]"></td>
              </tr>
            </table>  
            <table id="bot" class="table" v-if="tapeta[1]" style="height: 25px">
              <tr><td class="tapeta" colspan="3"></td></tr>
            </table>            
        </div>
      </b-row>
      <b-button type="button" variant="success" block @click="next"> Aceptar </b-button>
      <b-button type="button" variant="warning" block @click="reset"> Cancelar </b-button>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';
import {
  BCard,
  BCol,
  BRow,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
  },
  
  data() {
    return {
      dessign : [],
      bg_img: require('@/assets/images/varrow.svg'),
      bottom_img: require('@/assets/images/harrow.svg'),
      table_y : null,
      table_x : 0,      
      register_y : 0,
    };
  },
  created () {
    this.dessign = this.divisions
    for (let i = 0; i < this.dessign.length; i++) {
      this.dessign[i].percent = (this.dessign[i].width / this.total_width) * 100
      for (let j = 0; j < this.dessign[i].rows.length; j++) {
        this.dessign[i].rows[j].percent = (this.dessign[i].rows[j].height  / this.total_height) * 100;
      }      
    } 
  },
  mounted () {
    let clientWidth = document.getElementById('esquema').clientWidth;
    clientWidth = clientWidth > 600 ? 600 : clientWidth;
    clientWidth = this.tapeta[2] ? (clientWidth -25) : clientWidth;
    clientWidth = this.tapeta[3] ? (clientWidth -25) : clientWidth;
    this.table_x = clientWidth;      
    this.table_y = (clientWidth * this.total_height) / this.total_width;
    this.register_y = (clientWidth * this.register) / this.total_width;
  },  
  methods : {
    next() {
      this.$http.post("/api/InsertDessign",{user : this.user.id,log : JSON.stringify(this.full_state) }).then((response) => {
        if (response.data.id) {
           this.$router.push('/elements');
        }else {
          alert('Los datos no han podido ser almacenados')
        }
    }); 
    },
    reset() {
      this.$router.push('/');
    }
  },
    computed: {
      ...mapState({
        register : state => state.ventanas.complements.registro_medida,
        user: state => state.auth.user,               
        divisions: state => state.ventanas.divisions,
        tapeta : state => state.ventanas.complements.tapeta_values,           
        total_width : state => state.ventanas.dimensions.width,
        total_height : state => state.ventanas.dimensions.height,
        full_state : state => state.ventanas
      }),
      ...mapGetters(['getDimensions'])
  }
};
</script>

}<style>
.innerDiv{
  position: relative;
  height: 100%;
  align-items : center;
  border : 1px solid black;
  padding : 5px ;
  background-color : rgba(0,0,0,0.1) !important ;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}
.bottom-arrow {
  position: absolute;
  right: 1px;
  bottom: 10px;
  width: 100%;
}
.input-left {
  padding: 1px;
  margin-left : 0px;
  background-color : white;
  font-size : 0.8em
}
.input-bottom{
  position: absolute;
  width: 4em;
  margin: 0 auto;
  left: 0;
  right: 0;  
  bottom: 5px;
  font-size: .7em;
  background-color: white;
}
#registro, #tap, #bot {
  width : 100%;
  max-width : 600px;
  margin : 0 auto
}
</style>